import { actions } from "astro:actions";
import { toast } from "sonner";
import { Button } from "./ui/button";
import { Input } from "./ui/input";

export default function Waitlist() {
  return (
    <form
      className="flex space-x-2"
      onSubmit={async (e) => {
        e.preventDefault();
        const target = e.target as HTMLFormElement;
        const formData = new FormData(target);
        const {data, error} = await actions.joinWaitlist(formData);
        target.reset();
        if (error) {
          toast.error("Failed to subscribe", {
            description: error.message,
          });
          return;
        }
        toast.success("You're on the waitlist!", {
          description: `${data!} will be notified when we launch.`,
        });
      }}
    >
      <Input
        className="max-w-lg flex-1 bg-gray-800 text-white border-gray-500"
        placeholder="Enter your email"
        type="email"
        name="email"
        required
      />
      <Button className="bg-white text-black" type="submit">
        Join The Waitlist
      </Button>
    </form>
  );
}
